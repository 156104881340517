import { expVal } from '@confluence/feature-experiments';

export enum EndOfPageRecContainerCohort {
	CONTROL = 'control',
	GRID = 'grid',
	CAROUSEL = 'carousel',
}

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const useWhichEndOfPageRecContainerToRender = (): EndOfPageRecContainerCohort => {
	return expVal(
		'confluence_end_of_page_recs_container',
		'cohort',
		'control',
	) as EndOfPageRecContainerCohort;
};
